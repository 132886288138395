import { useMemo } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import ConversionNumber from 'bloko/blocks/conversion';
import Gap from 'bloko/blocks/gap';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { Employer } from 'lux/models/employersList';
import OrganizationForm from 'src/components/Employer/OrganizationForm';
import Pagination from 'src/components/Pagination';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './companies.less';

const TrlKeys = {
    employersOne: 'main.counter.employers.one',
    employersSome: 'main.counter.employers.some',
    employersMany: 'main.counter.employers.many',
    companyOne: 'search.company.found.one',
    companyMany: 'search.company.found.many',
};

const MAX_COMPANIES_PER_LIST = 34;

const Companies: TranslatedComponent = ({ trls }) => {
    const { employers, totalEmployersFound, paging } = useSelector((state) => state.employersList);

    const separatedEmployers = useMemo(() => {
        if (!employers.length) {
            return [];
        }
        const memo: Employer[][] = [[employers[0]]];
        let listIndex = 0;

        for (let i = 1; i < employers.length; i++) {
            const employer = employers[i];
            if (i % MAX_COMPANIES_PER_LIST === 0) {
                listIndex += 1;
                memo[listIndex] = [];
            }
            memo[listIndex].push(employer);
        }

        return memo;
    }, [employers]);

    return (
        <>
            <div className={styles.totals}>
                <Text Element="span" strong>
                    {totalEmployersFound}{' '}
                </Text>
                <Text Element="span">
                    <ConversionNumber
                        value={totalEmployersFound}
                        hasValue={false}
                        one={trls[TrlKeys.employersOne]}
                        some={trls[TrlKeys.employersSome]}
                        many={trls[TrlKeys.employersMany]}
                        zero={trls[TrlKeys.employersMany]}
                    />{' '}
                </Text>
                <Text Element="span">
                    <ConversionNumber
                        value={totalEmployersFound}
                        hasValue={false}
                        one={trls[TrlKeys.companyOne]}
                        some={trls[TrlKeys.companyMany]}
                        many={trls[TrlKeys.companyMany]}
                        zero={trls[TrlKeys.companyMany]}
                    />
                </Text>
            </div>
            {employers.length > 0 ? (
                <>
                    <table className={styles.companiesContainer}>
                        <tbody>
                            <tr>
                                {separatedEmployers.map((employers, index) => (
                                    <td data-qa="employers-list-company-list" key={index} className={styles.cell}>
                                        {employers.map(({ id, name, organizationFormId, vacanciesOpen }) => (
                                            <div key={id} className={styles.item}>
                                                <Link to={`/employer/${id}`}>{name}</Link>
                                                {', '}
                                                <OrganizationForm organizationFormId={organizationFormId} />{' '}
                                                <Text
                                                    data-qa={`employers-list-vacancies-open-${name}`}
                                                    Element="span"
                                                    italic
                                                >
                                                    {vacanciesOpen}
                                                </Text>
                                            </div>
                                        ))}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    {paging && (
                        <Gap top>
                            <Pagination {...paging} />
                        </Gap>
                    )}
                </>
            ) : null}
        </>
    );
};

export default translation(Companies);
